<template>
  <ul class="bx-header__channel-navi bx-typo--header-channel-navi">
    <li :class="getStyleClasses({ isActive: isMenuActive()})">
      <a href="/">
        Whitelabel
      </a>
    </li>
    <li v-for="channel in menuChannels"
        :key="channel.index"
        :class="getStyleClasses(channel)">
      <a :href="channel.url"
         :target="channel.target">
        {{ channel.name }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    menuChannels: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isChannelMenuActive: false
    }
  },
  methods: {
    isMenuActive () {
      return !this.checkForActiveChannelMenu()
    },
    checkForActiveChannelMenu () {
      this.menuChannels.forEach((channel) => {
        if (channel.isActive && channel.isActive === true) {
          this.isChannelMenuActive = true
        }
      })
      return this.isChannelMenuActive
    },
    getStyleClasses (channel) {
      return 'bx-header__channel-navi-item' + (channel.isActive && channel.isActive === true ? ' bx-js-active' : '')
    }
  }
}
</script>
