<template>
  <div class="bx-footer__abo-teaser bx-form bx-typo--form">
    <div class="bx-footer__abo-teaser-image">
      <img :src="coverUrl"
           loading="lazy"
           width="2"
           height="3"
           alt="Cover der aktuellen Ausgabe der Happinez"
           title="Cover der aktuellen Ausgabe der Happinez">
    </div>
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        Happinez Abo
      </span>
      <a
        class="bx-form__button"
        target="_blank"
        rel="noopener"
        href="https://www.happinez.de/abo/">
        Abo abschließen
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coverUrl: {
      type: String,
      default: null
    }
  }
}
</script>
