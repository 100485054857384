<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <div class="bx-newsletter-subscription bx-typo--newsletter-subscription bx-newsletter-subscription--footer">
            <div class="bx-newsletter-subscription__headline">
              Abonniere unseren Newsletter
            </div>
            <a href="https://www.happinez.de/ueber-happinez/newsletter-anmeldung/"
               class="bx-button">
              Jetzt anmelden
            </a>
          </div>
          <FooterAboTeaser :cover-url="coverUrl" />
          <SocialLinks menu-type="footer" />
        </div>
      </div>

      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MenuList from '../../../components/menu/MenuList.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'
import FooterAboTeaser from './FooterAboTeaser.vue'

export default {
  components: {
    MenuList,
    SocialLinks,
    FooterAboTeaser
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    },
    brand: {
      type: String,
      default: null
    }
  },
  computed: {
    coverUrl () {
      return `${process.env.COVER_URL}${this.brand.toLowerCase()}.jpg`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.matchNavHeight()
    })

    window.addEventListener('resize', () => {
      this.matchNavHeight()
    })

    this.addPaymentMethodsToFooterNaviList()
  },
  methods: {
    matchNavHeight () {
      const navHeight = document.querySelector('.bx-header__navi-wrapper').clientHeight + 'px'
      document.body.style.setProperty('--hz-header-flooding-after-height', navHeight)
    },
    addPaymentMethodsToFooterNaviList () {
      const paymentMethodsMarkup = `
        <li class="bx-js-has-dropdown">
          <a href="javascript: return false;">Zahlungsmittel</a>
          <ul class="bx-js-is-dropdown bx-footer__navi-list bx-footer__navi-list--level-1 bx-footer__navi-list--payment-methods">
            <li><img src="images/happinez/paypal.svg" alt="Paypal"></li>
            <li><img src="images/happinez/lastschrift.png" alt="Lastschrift"></li>
            <li><img src="images/happinez/mastercard.svg" alt="Mastercard"></li>
            <li><img src="images/happinez/visa.svg" alt="Visa"></li>
          </ul>
        </li>`
      document.querySelector('.bx-footer__navi-list.bx-footer__navi-list--level-0').insertAdjacentHTML('beforeend', paymentMethodsMarkup)
    }
  }
}
</script>
