<template>
  <PageWrapper :page-data="pageData"
               :brand="brandFromStore">
    <template #header-logo>
      <HeaderLogo />
      <Cart />
    </template>
    <template v-if="pageData.header.menuChannels"
              #header-channel-navigation>
      <HeaderChannelNavigation :menu-channels="pageData.header.menuChannels" />
    </template>
    <template #footer>
      <PageFooter :brand="brandFromStore"
                  :footer-data="pageData.footer" />
    </template>
  </PageWrapper>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
// these components will be injected into the app via slots
import PageWrapper from '../../components/PageWrapper.vue'
import defaultAppInit from '../../mixins/default-app-init'
import Cart from './external/Cart.vue'
import PageFooter from './theme/Footer.vue'
import HeaderChannelNavigation from './theme/HeaderChannelNavigation.vue'
import HeaderLogo from './theme/HeaderLogo.vue'

export default {
  components: {
    HeaderLogo,
    HeaderChannelNavigation,
    PageFooter,
    PageWrapper,
    Cart
  },
  mixins: [defaultAppInit],
  computed: {
    ...mapState(usePageStore, ['pageData', 'brandFromStore'])
  }
}
</script>
