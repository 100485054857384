<template>
  <div
    v-if="isClient"
    ref="rootDiv"
    :class="{ dropup: top, dropdown: true }"
    @mouseleave="mouseLeave"
    @mouseover="mouseOver"
    @mouseenter="mouseEnter"
    @click="toggleMenu">
    <slot />
    <transition :name="transition">
      <div
        v-show="showDropdown"
        ref="dropdown"
        :class="{ 'dropdown-menu-right': right, 'dropdown-menu': true, 'show': true }"
        :style="styles"
        @mouseleave="startTimer"
        @mouseenter="stopTimer"
        @click.stop>
        <slot name="dropdown" />
      </div>
    </transition>
  </div>
</template>

<script>
import clientOnly from '../../../../mixins/client-only'

export default {
  mixins: [clientOnly],
  props: {
    showDropdown: Boolean,
    right: Boolean,
    hover: Boolean,
    hoverTime: {
      type: Number,
      default: 100
    },
    hoverTimeout: {
      type: Number,
      default: 500
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    interactive: { // whether should stay open until clicked outside
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: ''
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updateShowDropdown'],
  data () {
    return {
      hovering: false,
      top: false
    }
  },
  watch: {
    showDropdown (v) {
      if (v) {
        this.top = false
        this.$nextTick(() => {
          const rect = this.$refs.dropdown.getBoundingClientRect()
          const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
          this.top = (rect.bottom > windowHeight) && (rect.top >= rect.height)
        }
        )
      }
    },
    interactive: {
      handler (value) {
        if (typeof document === 'object') { value ? document.body.addEventListener('click', this.closeMenu) : document.body.removeEventListener('click', this.closeMenu) }
      },
      immediate: true
    }
  },
  beforeUnmount () {
    document.body.removeEventListener('click', this.closeMenu)
  },
  methods: {
    mouseEnter () {
      this.stopTimer()
      if (this.hover && this.hoverTime > 0 && !this.showDropdown) {
        this.hoverOpenTimer = setTimeout(() => {
          this.$emit('updateShowDropdown', true)
          // disable for a moment
          this.hovering = true
          setTimeout(() => {
            this.hovering = false
          }, this.hoverTimeout)
        }, this.hoverTime)
      }

      if (this.hover && !this.showDropdown && this.hoverTime === 0) {
        this.hovering = true
        setTimeout(() => {
          this.hovering = false
        }, this.hoverTimeout)
        this.$emit('updateShowDropdown', true)
      }
    },
    mouseLeave () {
      if (!this.hoverTimer) { // left the link and no time active
        this.startTimer()
      }

      if (this.hoverTime > 0 && this.hover) {
        clearTimeout(this.hoverOpenTimer)
      }
    },
    mouseOver () {
      this.stopTimer()
    },
    closeMenu ($event) {
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (!$event || !this.$refs.rootDiv.contains($event.target)) {
        if (this.showDropdown && this.closeOnClickOutside) {
          this.$emit('updateShowDropdown', false)
        }
      }
    },
    toggleMenu () {
      if (this.hovering) { return }
      if (this.showDropdown && this.hover) { return }
      this.$emit('updateShowDropdown', !this.showDropdown)
    },
    stopTimer () {
      clearTimeout(this.hoverTimer)
      this.hoverTimer = null
    },
    startTimer () {
      if (!this.interactive) { this.hoverTimer = setTimeout(this.closeMenu, this.hoverTimeout) }
    }
  }
}
</script>
