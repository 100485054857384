<template>
  <div>
    <a class="bx-header__logo bx-header__logo--happinez"
       title="magazin.happinez.de"
       href="https://www.happinez.de/">
      <img src="/images/happinez/happinez-logo.svg"
           alt="Happinez Logo"
           width="302"
           height="62">
    </a>
  </div>
</template>
